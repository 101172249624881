<script setup lang="ts">
interface _VTI_TYPE_WritableComputedRef {
    value: _VTI_TYPE_T;
    /**
     * Type differentiator only.
     * We need this to be in public d.ts but don't want it to show up in IDE
     * autocomplete, so we use a private Symbol instead.
     */
    [RefSymbol]: true;
    readonly effect: _VTI_TYPE_ReactiveEffect;
}
interface EditorFormInputProps {
    label?: string;
    inlineLabel?: boolean;
    placeholder?: string;
    elementClassName?: string;
    containerClassName?: string;
    className?: string;
    inputId?: string;
    inputType?: string;
    step?: number;
    disabled?: boolean;
    withoutLabel?: boolean;
    tooltip?: string;
    autofocus?: boolean;
    required?: boolean;
    translated?: boolean;
    withVariables?: boolean;
    form: _VTI_TYPE_Form;
    model?: _VTI_TYPE_WritableComputedRef;
    inputName: string;
    withNullOption?: boolean;
        variablesSubmenuPosition?: string;
        withoutVariables?: boolean;
        withTemplateVariables?: boolean;
    }
    import { Quill, QuillEditor } from '@vueup/vue-quill'
    // import '@vueup/vue-quill/dist/vue-quill.bubble.css';
    import '@vueup/vue-quill/dist/vue-quill.snow.css';
    import { FormInputData } from '~~/composables/useFormInput'
    const { $theme } = useNuxtApp();
    const fontSizes = ref(['8px', '9px', '10px', '11px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '36px', '48px', '60px', '72px', '96px']);
    const fontSizePlugin = Quill.imports['attributors/style/size'];
    fontSizePlugin.whitelist = fontSizes.value;
    Quill.register(fontSizePlugin, true);
    
    const props = defineProps({
  label: null,
  inlineLabel: { type: Boolean },
  placeholder: null,
  elementClassName: { default: 'pb-6' },
  containerClassName: null,
  className: null,
  inputId: null,
  inputType: null,
  step: null,
  disabled: { type: Boolean },
  withoutLabel: { type: Boolean },
  tooltip: null,
  autofocus: { type: Boolean },
  required: { type: Boolean },
  translated: { type: Boolean },
  withVariables: { type: Boolean },
  form: null,
  model: null,
  inputName: null,
  withNullOption: { type: Boolean },
  variablesSubmenuPosition: { default: 'right-0' },
  withoutVariables: { type: Boolean, default: false },
  withTemplateVariables: { type: Boolean, default: false }
});
    const inputData: FormInputData = useFormInput(props, {
        className: $theme('form.textarea'),
    });
    const setModel = (content: string) => {
        inputData.model.value = content;
    };
    const colors = useColors();
    const setColor = (color: string) => {
        quillEditor.value?.getQuill().format('color', color);
    };
    const setFontSize = (fontSize: string) => {
        quillEditor.value?.getQuill().format('size', fontSize);
    };
    const addVariable = (variable: string) => {
        const value = inputData.model.value;
        quillEditor.value?.setContents(`${value}%${variable}%`);
    };
    const id = `editor-${Math.random().toString(36).substr(2, 9)}`;
    const quillEditor = ref(null);
    const quillOptions = ref({
        bounds: `#${id}`,
    });
    onMounted(async () => {
        const container = document.getElementById(id);
        const input = document.querySelector('.ql-editor');
        if (input && container) {
            input.addEventListener('focus', () => {
                container.classList.add('ql-focused');
            });
            input.addEventListener('blur', () => {
                container.classList.remove('ql-focused');
            });
        }
        if (props.autofocus && input) {
            console.log('input mounted', input, props.autofocus);
            await wait(250);
            input.focus();
        }
        // var toolbarElement = document.getElementById(`${id}-toolbar`);
        // var observer = new MutationObserver(function(mutations) {
        //     if (document.contains(toolbarElement)) {
        //         console.log("It's in the DOM!");
        //         observer.disconnect();
        //     } else {
        //         console.log("It's not in the DOM!");
        //     }
        // });
        // observer.observe(document, {attributes: false, childList: true, characterData: false, subtree:true});
    });
</script>

<template>
    <FormElement
        :id="id"
        :input-data="inputData"
        class="relative editor-container group/editor-container"
    >
        <slot name="before-input" />
        <div class="relative editor-inner-container">
            <QuillEditor
                ref="quillEditor"
                :key="inputData.elementKey.value"
                theme="snow"
                :content="inputData.model.value"
                :options="quillOptions"
                content-type="html"
                :toolbar="`#${id}-toolbar`"
                @update:content="setModel"
            >
                <template #toolbar>
                    <div
                        :id="`${id}-toolbar`"
                        class="flex gap-2 p-1 ql-editor-toolbar relative"
                    >
                        <div>
                            <Submenu relative-div-class="h-[24px]">
                                <template #default="{ toggle }">
                                    <slot :toggle="toggle">
                                        <button
                                            class="flex items-start justify-center !pt-0 ql-fontsize ql-picker ql-fontsize-picker"
                                            :data-tooltip="$t('misc.editor.fontSize')"
                                            @click.prevent="toggle"
                                        >
                                            <i class="text-sm fa-regular fa-text-size"></i>
                                        </button>
                                    </slot>
                                </template>
                                <template #submenu="{ hide }">
                                    <div :class="$theme('submenu.items')">
                                        <span
                                            v-for="fontSize in fontSizes"
                                            :key="fontSize"
                                            :class="$theme('submenu.item', 'cursor-pointer')"
                                            @click.prevent="
                                                setFontSize(fontSize);
                                                hide();
                                            "
                                        >
                                            {{ fontSize }}
                                        </span>
                                    </div>
                                </template>
                            </Submenu>
                        </div>
                        <div>
                            <button
                                class="ql-bold"
                                :data-tooltip="$t('misc.editor.bold')"
                            ></button>
                            <button
                                class="ql-italic"
                                :data-tooltip="$t('misc.editor.italic')"
                            ></button>
                            <button
                                class="ql-underline"
                                :data-tooltip="$t('misc.editor.underline')"
                            ></button>
                            <button
                                class="ql-strike"
                                :data-tooltip="$t('misc.editor.strike')"
                            ></button>
                        </div>
                        <div>
                            <button
                                class="ql-list"
                                value="ordered"
                                :data-tooltip="$t('misc.editor.orderedList')"
                            ></button>
                            <button
                                class="ql-list"
                                value="bullet"
                                :data-tooltip="$t('misc.editor.bulletList')"
                            ></button>
                        </div>
                        <div class="flex items-center">
                            <Submenu relative-div-class="h-[24px]">
                                <template #default="{ toggle }">
                                    <slot :toggle="toggle">
                                        <button
                                            class="ql-color ql-picker ql-color-picker"
                                            :data-tooltip="$t('misc.editor.color')"
                                            @click.prevent="toggle"
                                        ></button>
                                    </slot>
                                </template>
                                <template #submenu="{ hide }">
                                    <div class="flex flex-wrap gap-2 w-[240px] py-2">
                                        <div
                                            v-for="color in colors"
                                            :key="color"
                                        >
                                            <span
                                                class="block w-6 h-6 border-2 border-white rounded-full cursor-pointer hover:shadow"
                                                :style="`background: ${color};`"
                                                @click.prevent="
                                                    setColor(color);
                                                    hide();
                                                "
                                            ></span>
                                        </div>
                                    </div>
                                </template>
                            </Submenu>
                        </div>
                        <div>
                            <FormPartialsVariablesSubmenu
                                v-if="!withoutVariables"
                                :submenu-position="variablesSubmenuPosition"
                                :with-template-variables="withTemplateVariables"
                                icon-color="text-primary"
                                @add-variable="addVariable"
                            />
                        </div>
                    </div>
                </template>
            </QuillEditor>
            <div class="absolute top-0 right-0 hidden pt-2 pr-2 group-hover/editor-container:block">
                <span class="inline-block h-[26px] w-[26px] pt-[3px] rounded-full bg-neutral-100 flex items-center justify-center">
                    <FormPartialsVariablesSubmenu
                        v-if="!withoutVariables"
                        :submenu-position="variablesSubmenuPosition"
                        :with-template-variables="withTemplateVariables"
                        icon-color="text-primary"
                        @add-variable="addVariable"
                    />
                </span>
            </div>
        </div>
        <slot name="after-input" />
    </FormElement>
</template>

<style>
    .ql-editor {
        min-height: 140px;
        max-height: 240px;
        border: 1px solid #e5e7eb;
        border-radius: 3px;
        transition: all 0.3s linear;
        background: white;
    }
    .ql-editor:hover {
        border: 1px solid black;
    }
    .ql-bubble .ql-tooltip {
        background-color: #edf2fb;
        transition: background-color 0.3s linear;
    }
    .ql-bubble .ql-tooltip:hover {
        background-color: #edf2fb;
    }
    .ql-bubble .ql-tooltip:not(.ql-flip) .ql-tooltip-arrow {
        border-bottom-color: #edf2fb;
        transition: border-bottom-color-color 0.3s linear;
    }
    .ql-bubble .ql-tooltip:hover:not(.ql-flip) .ql-tooltip-arrow {
        border-bottom-color: #edf2fb;
    }
    .ql-bubble .ql-stroke {
        stroke: #0d00e8;
    }
    .ql-bubble .ql-toolbar button {
        transition: all 0.3s linear;
        border-radius: 3px;
    }
    .ql-bubble .ql-toolbar button.ql-picker {
        color: #0d00e8;
    }
    .ql-bubble .ql-toolbar button .ql-fill {
        fill: #0d00e8;
    }
    .ql-bubble .ql-toolbar button:hover .ql-bubble .ql-toolbar button.ql-active {
        background: #ccdbfd;
    }
    ,
    .ql-bubble .ql-toolbar button.ql-picker:hover,
    .ql-bubble .ql-toolbar button.ql-active.ql-picker {
        color: #0c0672;
    }
    .ql-bubble .ql-toolbar button:hover .ql-stroke,
    .ql-bubble .ql-toolbar button.ql-active .ql-stroke {
        stroke: #0c0672;
    }
    .ql-bubble .ql-toolbar button:hover .ql-fill,
    .ql-bubble .ql-toolbar button.ql-active .ql-fill {
        fill: #0c0672;
    }
    .ql-focused {
        position: relative;
        z-index: 1;
    }
    .editor-container:hover {
        z-index: 9;
    }
</style>
